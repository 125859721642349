import React, { Component } from "react";
import api from '../service'
import axios from "axios";

class Slide2_2 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      Advisor: [],
      errors: {}
    };
  }

   componentDidMount() {
    var config = {
      method: "get",
      url: api.getting_user_details+this.props.values.requestId,
      headers: {},
    };
    axios(config)
      .then((response) => {
          this.setState({
            Advisor: response.data
        });
        })
        .catch((error) => {
          console.log(error);
        });
  }




  

   handlesubmit = (event) => {
    if(this.validateForm()) {

      if(this.props.values.decision===""){
      var decision = "NA"
      }
      else{
        var decision = this.props.values.decision
      }

    const obj12 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  "21",
        "questionId": "9",
        "responseInfo": decision,
        "createDt": this.props.values.date
    }
    const obj13 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  this.props.values.distance,
        "questionId": "7",
        "responseInfo": "NA",
        "createDt": this.props.values.date
    }
    const obj14 = {
      "requestid": this.props.values.requestId,
      "medium": this.props.values.medium,
      "answerId":  "22",
      "questionId": "10",
      "responseInfo": this.props.values.advisorpokenTo.toString(),
      "createDt": this.props.values.date
    }
    // console.log(obj12,obj13,obj14)
     this.props.handleSubmit3(obj12,obj13,obj14);
    }
  }


  validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    // if(this.props.values.decision==="") {
    //     formIsValid = false;
    //     errors["decisionerror"] = "Kindly select an option below to proceed";
    //   }

    if(this.props.values.distance === ""){
        formIsValid = false;
        errors["locationerror"] = "Kindly select an option below to proceed";
      }
    if(!this.props.values.advisorpokenTo.length){
        formIsValid = false;
        errors["spokenToerror"] = "Kindly select an option below to proceed";
    }
    this.setState({
        errors: errors
    });
    return formIsValid;
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
 
     
  render() {
    const {handleInput,handlecheckbox2,values} = this.props;
    return (
      <>
    
        <section className="feedback-service">
          <div className="container" style={{ textAlign: "left" }}>

            <div className="row">
               <div className="col-md-12">
          <h2>Which of the advisor matches have you spoken to?
          <div id="rsp_email" className="error">{this.state.errors.spokenToerror}</div>
          </h2>

          {this.state.Advisor.map((checkbox) =>
          <label className="radio-inline" key={checkbox.sellerId}>
          <input type="checkbox" disabled={values.checknon === "None of the above"} checked={values.advisorpokenTo.includes(checkbox.sellerNm + "(Sid-"+ checkbox.sellerId+ ")")} name={checkbox.sellerNm} value={checkbox.sellerId} onChange={handlecheckbox2} />
          {checkbox.sellerNm}
          </label>
        )}
          <label className="radio-inline">
            <input
              type="checkbox"
              name="spokenTo"
              checked={values.advisorpokenTo.includes("None of the above")}
              value="None of the above"
              onChange={handlecheckbox2} 
            />
            None of the above
          </label>
        </div>

        <div className="col-md-12">
          <h2>
            How important is the location and the distance of your desired
            advisor from where you are located?
            <div id="rsp_email" className="error">{this.state.errors.locationerror}</div>
          </h2>

          <label className="radio-inline">
            <input type="radio" checked={values.distance==="17"} name="distance" value="17" onChange={handleInput(this)} />
            Not important
          </label>
          <label className="radio-inline">
            <input type="radio" name="distance" checked={values.distance==="18"} value="18"  onChange={handleInput(this)} />
            Somewhat important
          </label>
          <label className="radio-inline">
            <input type="radio" name="distance" checked={values.distance==="19"} value="19" onChange={handleInput(this)} />
            Very important
          </label>
        </div>

        <div className="col-md-12">
          <h2>
            Is there anything we can do to assist you with your decision making process?
          </h2>
          <input
            type="text"
            className="additional"
            name="decision"
            value={values.decision}
            onChange={handleInput(this)}
          ></input>
          <div id="rsp_email" className="error">{this.state.errors.decisionerror}</div>
        </div>

        <div className="col-md-3"></div>
        <div className="col-md-6">

        <input
          type="button"
          name="previous"
          className="next btn-update action-button nextOne"
          style={{ cursor: "pointer"}}
          value="PREVIOUS"
          onClick={this.back}
        />
        <input
          type="button"
          name="next"
          className="next btn-update action-button nextOne"
          style={{
            cursor: "pointer",
            marginLeft: "30px",
          }}
          value="NEXT"
          onClick={this.handlesubmit}
        />

        </div>


        </div>

          </div>

        </section>

      </>
    );
  }
}

export default Slide2_2;
