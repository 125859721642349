import React, { Component } from "react";

export default class ProgressBar extends Component {

  state = {
    width: "8.2909%",
    stepCount: 4,
  };



  render() {
    const { values } = this.props;
    
    // console.log("licount", liCount.length);


    if(values.status === "1"){
    var styleVariable = 100 / this.state.stepCount - 0.8 + "%";
    }
    else {
      var styleVariable = 100 / 3 - 0.8 + "%";
    }




    return (
      <>
       <ul id="progressbar">
            <li className="first active" style={{width: styleVariable}}></li>
            <li  className={
                  values.step === 2 ||
                  values.step === 3 ||
                  values.step === 4 ||
                  values.step === 5
                    ? "second active"
                    : "second"
                }  style={{width: styleVariable}}></li>
            <li className={
                  values.step === 3 ||
                  values.step === 4 ||
                  values.step === 5
                    ? "third active"
                    : "third"} style={{width: styleVariable}}></li>

            {values.status === "1" ? (<li className={
                  values.step === 4 ||
                  values.step === 5
                    ? "fourth active"
                    : "fourth"} style={{width: styleVariable}}></li>
): null}
            
          </ul>
      </>
    );
  }
}
