export default {
	  submit_response:
	    "https://survey.wiseradvisor.com/response",
	  getting_user_details: "https://survey.wiseradvisor.com/response/sellerdetails/"
	};

/*export default {
	  submit_response:
	    "http://localhost:8080/response",
	  getting_user_details: "http://localhost:8080/response/sellerdetails/"
	};*/




