import React, { Component } from "react";
class Slide3 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      satisfied: '',
      services: '',
      communicating:'',
      hear:'',
      hearinfo:'',
      recommend: '',
      errors: {}
    };
  }

  handleForm = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handlesubmit = (event) => {
    if (this.validateForm()) {
    const obj7 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  this.state.satisfied,
        "questionId": "17",
        "responseInfo": "NA",
        "createDt": this.props.values.date
    }

    if(this.state.services===""){
      var services = "NA"
    }
    else{
      var services = this.state.services
    }

    const obj8 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  "43",
        "questionId": "18",
        "responseInfo": services,
        "createDt": this.props.values.date
    }


    if(this.state.communicating===""){
      var communicating = "NA"
    }
    else{
      var communicating = this.state.communicating
    }

    const obj9 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  "44",
        "questionId": "19",
        "responseInfo": communicating,
        "createDt": this.props.values.date
    }
    
    if(this.state.hear==="49"){
     var hearOther = this.state.hearinfo
    }
    else{
      var hearOther= 'NA'
    }
    const obj10 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  this.state.hear,
        "questionId": "20",
        "responseInfo": hearOther,
        "createDt": this.props.values.date
    }

    const obj11 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  this.state.recommend,
        "questionId": "21",
        "responseInfo": "NA",
        "createDt": this.props.values.date
    }
   
     this.props.handleSubmit5(obj7,obj8,obj9,obj10,obj11);
  }
  }

  validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (fields.satisfied==="") {
        formIsValid = false;
        errors["satisfiederror"] = "Kindly select an option below to proceed";
      }

    // if(fields.communicating === ""){
    //     formIsValid = false;
    //     errors["communicatingerror"] = "Kindly select an option below to proceed";
    // }
    if(fields.hear === ""){
        formIsValid = false;
        errors["hearerror"] = "Kindly select an option below to proceed";
    }
    if(fields.hear === "49" && fields.hearinfo === ""){
        formIsValid = false;
        errors["hearinfoerror"] = "This is a required field";
    }
    if(fields.recommend === ""){
        formIsValid = false;
        errors["recommenderror"] = "Kindly select an option below to proceed";
    }
    this.setState({
        errors: errors
    });
    return formIsValid;
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {

    return (
      <>
      
          <section className="feedback-service">
          <div className="container" style={{ textAlign: "left" }}>

            <div className="row">
               <div className="col-md-12">
          <h2>How satisfied were you with WiserAdvisor's matching service?
          <div id="rsp_email" className="error">{this.state.errors.satisfiederror}</div>
          </h2>
          <div className="ranking-scale">
            <label className="radio-inline">Not satisfied</label>
            <label className="radio-inline">Satisfied</label>
            <label className="radio-inline">Very satisfied</label>
</div>
            <div className="scale-rating">
  <label value="1" className={this.state.satisfied === "38" ? "radioactive" : "nonactive"} >
  <input type="radio" value="38"  name="satisfied" onChange={this.handleForm}/>
  <label style={{width:"100%"}}></label>
  </label>
    <label value="2" className={this.state.satisfied === "39" ? "radioactive" : "nonactive"}>
  <input type="radio"  value="39"  name="satisfied" onChange={this.handleForm}/>
  <label  style={{width:"100%"}}></label>
  </label>
  <label value="3" className={this.state.satisfied === "40" ? "radioactive" : "nonactive"}>
  <input type="radio" value="40"   name="satisfied" onChange={this.handleForm}/>
  <label  style={{width:"100%"}}></label>
  </label>
  <label value="4" className={this.state.satisfied === "41" ? "radioactive" : "nonactive"}>
  <input type="radio" value="41"  name="satisfied" onChange={this.handleForm}/>
  <label style={{width:"100%"}}></label>
  </label>
  <label value="5" className={this.state.satisfied === "42" ? "radioactive" : "nonactive"}>
  <input type="radio" value="42"  name="satisfied" onChange={this.handleForm}/>
  <label  style={{width:"100%"}}></label>
  </label>
  </div>
        </div>
        <div className="col-md-12">
          <h2>How would you improve our matching services?
          <div id="rsp_email" className="error">{this.state.errors.serviceserror}</div> </h2>
          <textarea class="additional textarea" name="services" rows="4" cols="50" onChange={this.handleForm}/>
        
        </div>
        <div className="col-md-12">
          <h2>Are there any additional methods of communication you would have liked to use with your advisor matches?
          <div id="rsp_email" className="error">{this.state.errors.communicatingerror}</div>  </h2>
          <input
            type="text"
            className="additional"
            name="communicating"
            onChange={this.handleForm}
          ></input>
        </div>
        <div className="col-md-12">
          <h2>
          How did you hear about us?
          <div id="rsp_email" className="error">{this.state.errors.hearerror}</div> 
          </h2>

          <label className="radio-inline">
            <input type="radio" name="hear" value="45" onChange={this.handleForm} />
          Internet Search
          </label>
          <label className="radio-inline">
            <input type="radio" name="hear" value="46" onChange={this.handleForm} />
           Online Ad
          </label>
          <label className="radio-inline">
            <input type="radio" name="hear" value="47" onChange={this.handleForm}/>
            Podcast
          </label>
          
          <label className="radio-inline">
            <input type="radio" name="hear" value="48" onChange={this.handleForm} />
            Friend or Family Member
          </label>
          <label className="radio-inline">
            <input type="radio" name="hear" value="49" onChange={this.handleForm} />
            Other
          </label>
          <input
            type="text"
            className="additional"
            name="hearinfo"
            disabled= {this.state.hear!=="49" ? 'disabled' : null}
            placeholder="IF OTHER PLEASE SPECIFY"
            onChange={this.handleForm}
          ></input>
          <div id="rsp_email" className="error">{this.state.errors.hearinfoerror}</div> 
        </div>
      
      
        <div className="col-md-12">
          <h2>How likely are you to recommend WiserAdvisor's financial matching services to others?
          <div id="rsp_email" className="error">{this.state.errors.recommenderror}</div> 

          </h2>
          <div className="ranking-scale">
          <label className="radio-inline">Not Likely</label>
          <label className="radio-inline">Maybe</label>
          <label className="radio-inline">Definitely</label>
          </div>
            <div className="scale-rating">
                    <label value="1" className={this.state.recommend === "50" ? "radioactive" : "nonactive"}>
                    <input type="radio" value="50"  name="recommend" onChange={this.handleForm}/>
                    <label style={{width:"100%"}}></label>
                    </label>
                      <label value="2" className={this.state.recommend === "51" ? "radioactive" : "nonactive"}>
                    <input type="radio"   value="51" name="recommend" onChange={this.handleForm}/>
                    <label  style={{width:"100%"}}></label>
                    </label>
                    <label value="3" className={this.state.recommend === "52" ? "radioactive" : "nonactive"}>
                    <input type="radio" value="52"  name="recommend" onChange={this.handleForm}/>
                    <label  style={{width:"100%"}}></label>
                    </label>
                    <label value="4" className={this.state.recommend === "53" ? "radioactive" : "nonactive"}>
                    <input type="radio" value="53"  name="recommend" onChange={this.handleForm}/>
                    <label style={{width:"100%"}}></label>
                    </label>
                    <label value="5" className={this.state.recommend === "54" ? "radioactive" : "nonactive"}>
                    <input type="radio" value="54"  name="recommend" onChange={this.handleForm}/>
                    <label  style={{width:"100%"}}></label>
                    </label>
                    </div>
       
        </div>

        <div className="col-md-3"></div>
        <div className="col-md-6">
        <input
          type="button"
          name="previous"
          className="next btn-update action-button nextOne"
          style={{ cursor: "pointer" }}
          value="PREVIOUS"
          onClick={this.back}
        />
        <input
          type="button"
          name="next"
          className="next btn-update action-button nextOne"
          style={{
            cursor: "pointer",
            marginLeft: "30px",
          }}
          value="SUBMIT"
          onClick={this.handlesubmit}
        />

      </div>
      </div>

          </div>

        </section>
      </>
    );
  }
}

export default Slide3;
