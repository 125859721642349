import React, { Component } from "react";
import api from '../service'
import axios from "axios";

class Slide2_3 extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      checkboxes: [],
      errors: {}
    };
  }

   componentDidMount() {
    var config = {
      method: "get",
      url: api.getting_user_details+this.props.values.requestId,
      headers: {},
    };
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
          this.setState({
            checkboxes: response.data
        });
        })
        .catch((error) => {
          console.log(error);
        });
  }





   handlesubmit = (event) => {
    if (this.validateForm()) {
      const obj15 = {
          "requestid": this.props.values.requestId,
          "medium": this.props.values.medium,
          "answerId":  this.props.values.location,
          "questionId": "13",
          "responseInfo": "NA",
          "createDt": this.props.values.date
      }
      if(this.props.values.communicating==="24"){
        var communicatingyes = this.props.values.communicatingyes.toString();
      }
      else{
        var communicatingyes =  "NA"
      }
      const obj16 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  this.props.values.communicating,
        "questionId": "11",
        "responseInfo": communicatingyes,
        "createDt": this.props.values.date
      }

      if(this.props.values.decision_making===""){
      var decision_making = "NA"
      }
      else{
        var decision_making = this.props.values.decision_making
      }

      const obj17 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  "21",
        "questionId": "9",
        "responseInfo": decision_making,
        "createDt": this.props.values.date
      }

      // console.log(obj15,obj16,obj17)
       this.props.handleSubmit3(obj15,obj16,obj17);
    }
  }

  validateForm() {
    let errors = {};
    let formIsValid = true;
    if (this.props.values.location==="") {
        formIsValid = false;
        errors["locationerror"] = "Kindly select an option below to proceed";
      }

    if(this.props.values.communicating === ""){
        formIsValid = false;
        errors["communicatingerror"] = "Kindly select an option below to proceed";
      }
    // if(this.props.values.decision_making === ""){
    //     formIsValid = false;
    //     errors["decisionmakingerror"] = "Kindly select an option below to proceed";
    // }
     if(this.props.values.communicating === "24" && !this.props.values.communicatingyes.length ){
        formIsValid = false;
        errors["communicatingyeserror"] = "Kindly select an option below to proceed";
    }
    this.setState({
        errors: errors
    });
    return formIsValid;
  }


  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {handleInput,handlecheckbox3,values} = this.props;
    return (
      <>
      

          <section className="feedback-service">
          <div className="container" style={{ textAlign: "left" }}>

            <div className="row">
               <div className="col-md-12">
          <h2>
            Is there anything we can do to assist you with your decision making
            process?
            <div id="rsp_email" className="error">{this.state.errors.decisionmakingerror}</div>
          </h2>
          <input
            type="text"
            style = {{marginBottom:"10px"}}
            className="additional"
            value={values.decision_making}
            name="decision_making"
            onChange={handleInput(this)}
          ></input>
        </div>
        <div className="col-md-12">
          <h2>Do you need help communicating with any of the advisors?

          <div id="rsp_email" className="error">{this.state.errors.communicatingerror}</div>
          <div id="rsp_email" className="error">{this.state.errors.communicatingyeserror}</div>
          </h2>

          <label className="radio-inline">
            <input
              type="radio"
              name="communicating"
              value="23"
              checked={values.communicating==="23"}
               onChange={handleInput(this)}
            />
            No
          </label>
          <label className="radio-inline">
            <input
              type="radio"
              name="communicating"
              value="24"
              checked={values.communicating==="24"}
               onChange={handleInput(this)}
            />
            Yes
          </label>
          <label className="radio-inline">
            <input
              type="radio"
              name="communicating"
              value="25"
              checked={values.communicating==="25"}
              onChange={handleInput(this)}
            />
            I'm no longer interested
          </label>

        </div>

        <div className="col-md-12">

          <p className="communicatingyes">If yes:</p>
           {this.state.checkboxes.map((checkbox) =>
          <label className="radio-inline" key={checkbox.sellerId}>
          <input type="checkbox"   disabled={values.communicating!=="24" ? 'disabled' : null} checked={values.communicatingyes.includes(checkbox.sellerNm + "(Sid-"+ checkbox.sellerId+ ")")} value={checkbox.sellerId} onChange={handlecheckbox3(checkbox.sellerNm)} />
          {checkbox.sellerNm}
          </label>
          )}

        

        </div>
        <div className="col-md-12">
          <h2>
            How important is the location and the distance of your desired
            advisor from where you are located?
            <div id="rsp_email" className="error">{this.state.errors.locationerror}</div>

          </h2>

          <label className="radio-inline">
            <input type="radio" name="location" value="27" checked={this.props.values.location==="27"} onChange={handleInput(this)} />
            Not important
          </label>
          <label className="radio-inline">
            <input type="radio" name="location" value="28"  checked={this.props.values.location==="28"} onChange={handleInput(this)} />
            Somewhat important
          </label>
          <label className="radio-inline">
            <input type="radio" name="location" value="29" checked={this.props.values.location==="29"} onChange={handleInput(this)} />
            Very important
          </label>
        </div>

        <div className="col-md-3"></div>
        <div className="col-md-6">
        <input
          type="button"
          name="previous"
          className="next btn-update action-button nextOne"
          style={{ cursor: "pointer" }}
          value="PREVIOUS"
          onClick={this.back}
        />
        <input
          type="button"
          name="next"
          className="next btn-update action-button nextOne"
          style={{
            cursor: "pointer",
            marginLeft: "30px",
          }}
          value="NEXT"
          onClick={this.handlesubmit}
        />
        </div>

        </div>

          </div>

        </section>
      </>
    );
  }
}

export default Slide2_3;
