import React, { Component } from "react";
import api from '../service'
import axios from "axios";

class Slide2_1_1 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      errors : [],
      Advisor: []
    };
  }

  componentDidMount() {
    var config = {
      method: "get",
      url: api.getting_user_details+this.props.values.requestId,
      headers: {},
    };
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
          this.setState({
            Advisor: response.data
        });
        })
        .catch((error) => {
          console.log(error);
        });
  }

  handleForm = (event) => {
    this.setState({[event.target.name]: event.target.value});
    }



  handlesubmit = () =>{
    if (this.validateForm()) {
      if(this.props.values.options1.includes("other")){
       let selected = this.props.values.options1
       let index = selected.indexOf('other')
       selected[index] = "other-" +  this.props.values.othersInfo
        // selected.push(selected[index]);
        // this.setState({options1:selected}, console.log("checkbox",this.state.options1))
      }

      if(this.props.values.interviewany === "12") {
          var selectedYes = this.props.values.options1.toString();
      }
      else{
       var selectedYes = "NA"
      }

    const obj4 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId":  this.props.values.interviewany,
        "questionId": "4",
        "responseInfo": selectedYes,
        "createDt": this.props.values.date
    }
     console.log(obj4)
     this.props.handleSubmit(obj4);
    }}
   

   validateForm() {
    let errors = {};
    let formIsValid = true;
    if(this.props.values.interviewany === "") {
        formIsValid = false;
        errors["interviewanyerror"] = "Kindly select an option below to proceed";
      }
    if(this.props.values.interviewany === "12" && !this.props.values.options1.length ){
      formIsValid = false;
        errors["options1error"] = "Kindly select an option below to proceed";
    }

    if(this.props.values.options1.includes("other") &&  this.props.values.othersInfo === ""){
      formIsValid = false;
        errors["othersInfoerror"] = "This is a required field";
    }

    this.setState({
        errors: errors
    });
    return formIsValid;
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {values,handleInput,handlecheckbox1} = this.props;

    return (
      <>
      
        <section className="feedback-service" >
          <div className="container" style={{ textAlign: "left" }}>

            <div className="row">
          
                <div className="col-md-12">
                  <h2>In your process, did you interview any other advisor besides {values.sellerName}?
                  <div id="rsp_email" className="error">{this.state.errors.interviewanyerror}</div>
                  </h2>
                    <label className="radio-inline">
                      <input type="radio" name="interviewany" checked={values.interviewany === "12"} value="12" onChange={handleInput(this)} />
                      Yes
                    </label>
                    <label className="radio-inline">
                      <input type="radio" name="interviewany"  checked={values.interviewany === "13"} value="13" onChange={handleInput(this)}   />
                      No
                    </label>
                  </div>
                  <div className="col-md-12">
                    <h2>If yes, please select below:
                    <div id="rsp_email" className="error">{this.state.errors.options1error}</div>
                    </h2>
                    

                     {this.state.Advisor.filter(checkbox => checkbox.sellerNm !==  values.sellerName).map((checkbox) => (
                        <label className="radio-inline" key={checkbox.sellerId}>
                        <input type="checkbox" checked={values.options1.includes(checkbox.sellerNm + "(Sid-"+ checkbox.sellerId+ ")")} name={checkbox.sellerNm} disabled={values.interviewany !== "12" ? 'disabled' : null} value={checkbox.sellerId} onChange={handlecheckbox1} />
                        {checkbox.sellerNm}
                        </label>
                      )
                      )}
                       <label className="radio-inline">
                      <input
                        type="checkbox"
                        checked={values.options1.includes("other") || values.options1.includes("other-"+values.othersInfo) }
                        disabled={values.interviewany !== "12" ? 'disabled' : null}
                        value="other"
                        onChange={handlecheckbox1} 
                      />
                      Other
                      </label>

                    <input
                      type="text"
                      className="additional"
                      name="othersInfo"
                      value={values.options1.includes("other") || values.options1.includes("other-"+values.othersInfo) ? values.othersInfo : ""}
                      disabled={!values.options1.includes("other")}
                      placeholder="IF OTHER PLEASE SPECIFY"
                     onChange={handleInput(this)}
                    ></input>
                    <div id="rsp_email" className="error">{this.state.errors.othersInfoerror}</div>
                    


                </div>
            
                <div className="col-md-3"></div>
                <div className="col-md-6">

                  <input
                        type="button"
                        name="previous"
                        className="next btn-update action-button nextOne"
                        style={{ cursor: "pointer" }}
                        value="PREVIOUS"
                        onClick={this.back}
                      />
                      <input
                        type="button"
                        name="next"
                        className="next btn-update action-button nextOne"
                        style={{
                          cursor: "pointer",
                          marginLeft: "30px",
                        }}
                        value="NEXT"
                        onClick={this.handlesubmit}
                      />

                </div>

            </div>

          </div>

        </section>

        



      </>
    );
  }
}

export default Slide2_1_1;
