import React, { Component } from "react";
import api from '../service'
import axios from "axios";

class Slide2_1 extends Component {

   constructor(props) {
    super(props);
    this.state = {
      Advisor: [],
      errors: []
    };
  }

   componentDidMount() {
    var config = {
      method: "get",
      url: api.getting_user_details+this.props.values.requestId,
      headers: {},
    };
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
          this.setState({
            Advisor: response.data
        });
        })
        .catch((error) => {
          console.log(error);
        });
  }


  onSubmitForm = (event) => {
    if (this.validateForm()) {
    event.preventDefault();
    const obj2 = {
        "requestid": this.props.values.requestId,
        "medium": this.props.values.medium,
        "answerId": "5",
        "questionId": "2",
        "responseInfo": this.props.values.sellerName+"(Sid-"+this.props.values.sellerId+")",
        "createDt": this.props.values.date
    }

    var option2 =  this.props.values.optradio1
    if(option2==="11")
    {
      var response = this.props.values.others
    }
    else
    {
      var response = "NA"
    }

    const obj3 = {
      "requestid": this.props.values.requestId,
      "medium": this.props.values.medium,
      "answerId":  option2,
      "questionId": "3",
      "responseInfo": response,
      "createDt": this.props.date
    }
     console.log(obj2)
     console.log(obj3)
     this.props.handleSubmit2(obj2,obj3);
    }
  }


  validateForm() {
    let fields = this.props.values;
    let errors = {};
    let formIsValid = true;
    if (fields.optradio1==="") {
        formIsValid = false;
        errors["optradio1error"] = "Kindly select an option below to proceed";
      }
    if(fields.optradio1 === "11" && fields.others === ""){
       formIsValid = false;
        errors["otherserror"] = "This is a required field";
    }
    if(fields.sellerId === ""){
       formIsValid = false;
        errors["sellererror"] = "Kindly select an option below to proceed";
    }
    this.setState({
        errors: errors
    });
    return formIsValid;
  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const {handleInput,handleRadio,values} = this.props;

    return (
      <>
     
        <section className="feedback-service" >
          <div className="container" style={{ textAlign: "left" }}>

            <div className="row">
               <div className="col-md-12">
                    <h2>Which of the advisor matches did you choose?
                    <div id="rsp_email" className="error">{this.state.errors.sellererror}</div>
                    </h2>
                    {this.state.Advisor.map((checkbox) =>
                      <label className="radio-inline" key={checkbox.sellerId}>
                      <input type="radio" name="sellerId" checked ={values.sellerId === checkbox.sellerId } value={checkbox.sellerNm} onChange={handleRadio(checkbox.sellerId)} />
                      {checkbox.sellerNm}
                      </label>
                    )}
                </div>

                <div className="col-md-12">
                  <h2>What was the main reason for hiring this advisor or firm?
                  <div id="rsp_email" className="error">{this.state.errors.optradio1error}</div>
                  </h2>
                    <label className="radio-inline">
                      <input type="radio" name="optradio1" checked={values.optradio1 === "6"} value="6"  onChange={handleInput(this)} />
                      Personality fit
                    </label>
                    <label className="radio-inline">
                      <input type="radio" name="optradio1" checked={values.optradio1 === "7"} value="7" onChange={handleInput(this)} />
                      Years of experience

                    </label>
                    <label className="radio-inline">
                      <input type="radio" name="optradio1" checked={values.optradio1 === "8"} value="8" onChange={handleInput(this)} />
                      Successful track record

                    </label>
                    <label className="radio-inline">
                      <input type="radio" name="optradio1" checked={values.optradio1 === "9"} value="9" onChange={handleInput(this)} />
                      Expertise in specific services


                    </label>
                    <label className="radio-inline">
                      <input type="radio" name="optradio1" checked={values.optradio1 === "10"} value="10" onChange={handleInput(this)} />
                      Location 


                    </label>
                    <label className="radio-inline">
                    <input type="radio" name="optradio1" checked={values.optradio1 === "11"} value="11" onChange={handleInput(this)} />
                      Other

                    </label>
                    <input
                      type="text"
                      className="additional"
                      placeholder="IF OTHER PLEASE SPECIFY"
                      disabled={values.optradio1 !== "11" ? 'disabled' : null}
                      name="others"
                      value={values.others}
                      onChange={handleInput(this)}
                    ></input>
                     <div id="rsp_email" className="error">{this.state.errors.otherserror}</div>
                </div>

               
            
                <div className="col-md-3"></div>
                <div className="col-md-6">

                  <input
                        type="button"
                        name="previous"
                        className="next btn-update action-button nextOne"
                        style={{ cursor: "pointer" }}
                        value="PREVIOUS"
                        onClick={this.back}
                      />
                      <input
                        type="button"
                        name="next"
                        className="next btn-update action-button nextOne"
                        style={{
                          cursor: "pointer",
                          marginLeft: "30px",
                        }}
                        value="NEXT"
                        onClick={this.onSubmitForm}
                      />

                </div>

            </div>

          </div>

        </section>

        



      </>
    );
  }
}

export default Slide2_1;
