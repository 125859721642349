import React from "react";

const Header = (props) => {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-12 col-lg-12">
          <a className="navbar-brand" href="https://www.wiseradvisor.com/">
          <img
                  src="https://static.wiseradvisor.com/wiseradvisor/v2/images/logo.png"
                  alt="WiserAdvisor" width="100%"
                /> 

          </a>
                          
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;