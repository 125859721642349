import React, { Component } from "react";

class Thankyou extends Component {
  render() {
    return (
      <>
        <fieldset className="field16 current" id="field16">
          <h3>
            If you have any further questions regarding the survey <br></br>
            please email us at <span><a href="mailto:wa.assistance@wiseradvisor.com">wa.assistance@wiseradvisor.com.</a></span>
          </h3>
        </fieldset>
      </>
    );
  }
}

export default Thankyou;
