import React, { Component } from "react";
import "./assets/stylesheet/style.css";
import ProgressBar from "./components/progressbar";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import Slide1 from "./components/Slide1";
import Slide2_1 from "./components/Slide2_1";
import Slide2_1_1 from "./components/slide2.1.1";
import Slide2_2 from "./components/Slide2_2";
import Slide2_3 from "./components/Slide2_3";
import Slide2_4 from "./components/Slide2_4";
import Slide3 from "./components/Slide3";
import Thankyou from './components/thankyou'
import api from './service'
import axios from "axios";

export default class App extends Component {
//  slide2.1
  state = {
    step: 1,
    date: '',
    requestId: '',
    medium: 'sms',
    status: "",
    questionId1:"",
    advisorMatches: "",

  //slide 2.1.1
    interviewany: '',
    options1:[],
    othersInfo:'',

  // slide 2.1
    optradio1: '',
    sellerId: '',
    others:'',
    sellerName: '',

  // slide 2.2
    decision: '',
    distance:'',
    advisorpokenTo: [],
    checknon: '',

  //slide2.3
    location: '',
    communicating:'',
    decision_making:'',
    communicatingyes: [],


    //slide2.4
    hiring: '',
    hiringothers: '',
    assist_with: '',
    checknon1:'',
    selectspokenTo:[],
  };




  handlecheckbox4 =  (event)  => {
    let sellerNm = event.target.name
    let selected = this.state.selectspokenTo
    var id = event.target.value

    if(id==="None of the above"){
      if(event.target.checked){
        this.setState({checknon1:"None of the above"})
      }
      else{
        this.setState({checknon1:""})
      }
    }
      let find = selected.indexOf(sellerNm+"(Sid-"+id+")")
      let none = selected.indexOf("None of the above") 
      if(find > -1 || none > -1) {
      selected.splice(find, 1)
      } 
      else {
        if(id==="None of the above"){
           selected.length = 0;
           selected.push("None of the above")
        }
        else {
          let none = selected.indexOf("None of the above") 
          if(none > -1) {
          selected.splice(none, 1)
          }  
        selected.push(sellerNm+"(Sid-"+id+")")
      }
    
  }
  this.setState({selectspokenTo:selected}, console.log("checkbox",this.state.selectspokenTo))
  }

    handlecheckbox1 =  (event)  => {
    let sellerNm = event.target.name
    let selected = this.state.options1
    var id = event.target.value
    let find = selected.indexOf(sellerNm+"(Sid-"+id+")")
    let others = selected.indexOf("other")
    let othersvalue = selected.indexOf("other-"+this.state.othersInfo)
    if(find > -1 || others > -1 || othersvalue > -1) {
    selected.splice(find, 1)
    } 
    else {
      if(id==="other"){
        selected.push(id)
      }
      else {
      selected.push(sellerNm+"(Sid-"+id+")")
    }
     //selected.push(id)
    
  }
    this.setState({options1:selected}, console.log("checkbox",this.state.options1))

  }

  handlecheckbox3 = (input) => (event)  => {
    const sellerNm = input
    let selected = this.state.communicatingyes
    var id = event.target.value
    let find = selected.indexOf(sellerNm+"(Sid-"+id+")")

    if(find > -1) {
    selected.splice(find, 1)
    } else {
    selected.push(sellerNm+"(Sid-"+id+")")
    }
    this.setState({communicatingyes:selected}, console.log("checkbox",this.state.communicatingyes))

  }



  handlecheckbox2 =  (event)  => {
    let sellerNm = event.target.name
    let selected = this.state.advisorpokenTo
    var id = event.target.value

    if(id==="None of the above"){
      if(event.target.checked){
        this.setState({checknon:"None of the above"})
      }
      else{
        this.setState({checknon:""})
      }
    }
      let find = selected.indexOf(sellerNm+"(Sid-"+id+")")
      let none = selected.indexOf("None of the above") 
      if(find > -1 || none > -1) {
      selected.splice(find, 1)
      } 
      else {
        if(id==="None of the above"){
           selected.length = 0;
           selected.push("None of the above")
          
        }
        else {
          let none = selected.indexOf("None of the above") 
          if(none > -1) {
          selected.splice(none, 1)
          }  
        selected.push(sellerNm+"(Sid-"+id+")")
      }
    
  }
  this.setState({advisorpokenTo:selected}, console.log("checkbox",this.state.advisorpokenTo))
  }






  handleForm = () => (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleRadio = (input) => e => {
    this.setState({[e.target.name]: input });
     this.setState({sellerName: e.target.value})
  }

  
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1,
    });
  };

  componentDidMount() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    this.setState({date: today});

    var url_string = window.location.href;
    var url = new URL(url_string);
    var req = url.searchParams.get("req");
    var medium = url.searchParams.get("medium");
    this.setState({requestId: req});
    if(medium==="email"){
      this.setState({medium: "email"});
    }
  }




  handleClick = (input, value) => (e) => {
    this.setState({ [input]: value });
    this.CreateObj1(value);
  };


  CreateObj1 = (event) => {
    const obj1 = {
        "requestid": this.state.requestId,
        "medium": this.state.medium,
        "answerId":  event,
        "questionId": "1",
        "responseInfo": "NA",
        "createDt": this.state.date
    }
    this.handleSubmit(obj1);
  }
 


handleSubmit = (obj1) => {
  console.log(obj1)
    var config = {
      method: "post",
      url: api.submit_response,
      headers: {
        // "Content-Type": "application/json"
      },
      data: obj1,
    };
    axios(config)
      .then((response) => {
        console.log(response);
        this.nextStep(this);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  handleSubmit2 = (obj1,obj2) => {
     var config1 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj1,
    };

     var config2 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj2,
    };
    // this.nextStep(this);  
    axios(config1)
      .then((response) => {
        console.log("first answers saved")
         axios(config2)
            .then((response) => {
                console.log("second answers saved")
                this.nextStep(this);  
            })
            .catch((error) => {
                console.log(error);
                console.log("Failed saving second answer")
                });
      })
      .catch((error) => {
        console.log(error);
        console.log("Failed saving first answer")
      });
  }



  handleSubmit3 = (obj1,obj2,obj3) => {
     var config1 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj1,
    };

      var config2 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj2,
    };

     var config3 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj3,
    };

        // this.nextStep(this)


    axios(config1)
      .then((response) => {
        console.log(response);
        console.log("first answers saved")
         axios(config2)
            .then((response) => {
                console.log(response);
                console.log("second answers saved")
              axios(config3)
                .then((response) => {
                console.log(response);
                console.log("third answers saved")
                this.nextStep(this)
                })
                .catch((error) => {
                  console.log(error);
                  console.log("Failed saving third answer")
                });
            })
            .catch((error) => {
                console.log(error);
                console.log("Failed saving second answer")
                });
      })
      .catch((error) => {
        console.log(error);
        console.log("Failed saving first answer")
      });
  }


handleSubmit5 = (obj1,obj2,obj3,obj4,obj5) => {
    var config1 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj1,
    };
    var config2 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj2,
      };
    var config3 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj3,
    };
    var config4 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj4,
    };
    var config5 = {
      method: "post",
      url: api.submit_response,
      headers: {
      },
      data: obj5,
    };
    axios(config1)
      .then((response) => {
        console.log(response);
        console.log("first answers saved")
         axios(config2)
            .then((response) => {
                console.log(response);
                console.log("second answers saved")
                axios(config3)
                  .then((response) => {
                    console.log(response);
                    console.log("third answers saved")
                    axios(config4)
                    .then((response) => {
                      console.log(response);
                      console.log("FOURTH answers saved")
                       axios(config5)
                      .then((response) => {
                       console.log(response);
                        console.log("FIFTH answers saved")
                        this.nextStep(this)
                        })
                      .catch((error) => {
                        console.log(error);
                        console.log("Failed saving FIRST answer")
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      console.log("Failed saving second answer")
                    });
                  })
                  .catch((error) => {
                    console.log(error);
                    console.log("Failed saving third answer")
                  });
                })
              .catch((error) => {
                console.log(error);
                console.log("Failed saving FOURTH answer")
                });
            })
        .catch((error) => {
          console.log(error);
          console.log("Failed saving FIFTH answer")
        });
    }







  render() {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <div>
            <Header />
            <section className="multi-bg">
              <div className="container" id="formcontainer">
                <div className="row">
                  <div className="col-12">
                    <div className="form-start text-center">
                      <h1 className="step-heading">
                        ADVISOR MATCH SERVICE FEEDBACK
                        <hr />
                      </h1>
                      <ProgressBar values={this.state}/>
                      <form id="helpdeskform" name="helpdeskform" method="post">
                        <Slide1
                          nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleClick={this.handleClick}
                          values={this.state}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        );
      case 2:
        return (
          <div>
            <Header />
            <section className="multi-bg">
              <div className="container" id="formcontainer">
                <div className="row">
                  <div className="col-12">
                    <div className="form-start text-center">
                      <h1 className="step-heading">
                        ADVISOR MATCH SERVICE FEEDBACK
                        <hr />
                      </h1>
                      <ProgressBar values={this.state}/>
                      <form id="helpdeskform" name="helpdeskform" method="post">
                        {this.state.status === "1" ? (
                          <Slide2_1
                            handleInput = {this.handleForm}
                            handleSubmit2 = {this.handleSubmit2}
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleRadio = {this.handleRadio}
                            values = {this.state}
                          />
                        ) : this.state.status === "2" ? (
                          <Slide2_2
                            handlecheckbox2 = {this.handlecheckbox2}
                            handleInput = {this.handleForm}
                            handleSubmit3 = {this.handleSubmit3}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            values={this.state}
                          />
                        ) : this.state.status === "3" ? (
                          <Slide2_3
                            handlecheckbox3 = {this.handlecheckbox3}
                            handleInput = {this.handleForm}
                            handleSubmit3 = {this.handleSubmit3}
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            values={this.state}
                          />
                        ) : this.state.status === "4" ? (
                          <Slide2_4
                            handlecheckbox4 = {this.handlecheckbox4}
                            handleInput = {this.handleForm}
                            values = {this.state}
                            handleSubmit3 = {this.handleSubmit3}
                            prevStep={this.prevStep}
                          />
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        );
      case 3:
        return (
          <div>
          <Header />
          <section className="multi-bg">
            <div className="container" id="formcontainer">
              <div className="row">
                <div className="col-12">
                  <div className="form-start text-center">
                    <h1 className="step-heading">
                      ADVISOR MATCH SERVICE FEEDBACK
                      <hr />
                    </h1>
                    <ProgressBar values={this.state}/>
                    <form id="helpdeskform" name="helpdeskform" method="post">
                    {this.state.status === "1" ? (
                   <Slide2_1_1
                   handlecheckbox1 = {this.handlecheckbox1}
                   handleSubmit = {this.handleSubmit}
                   prevStep={this.prevStep}
                   nextStep = {this.nextStep}
                   handleInput = {this.handleForm}
                   values={this.state}/>
                    ):    <Slide3
                    handleSubmit5 = {this.handleSubmit5}
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    values={this.state}
                  /> 
                    }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
        );
        case 4:
          return (
            <div>
            <Header />
            <section className="multi-bg">
              <div className="container" id="formcontainer">
                <div className="row">
                  <div className="col-12">
                    <div className="form-start text-center">
                     {this.state.status === "1" ? (
                      <h1 className="step-heading">
                         ADVISOR MATCH SERVICE FEEDBACK
                       <hr />
                      </h1>
                      ):  
                      <h1 className="step-heading thanks-heading">
                      Thank you for completing our survey.
                      <br></br>
                      We always value feedback.

                        <hr />
                      </h1>
                    }
                      {this.state.status === "1" ? (<ProgressBar values={this.state}/>): null}
                      <form id="helpdeskform" name="helpdeskform" method="post">
                      {this.state.status === "1" ? (
                        <Slide3
                        handleSubmit5 = {this.handleSubmit5}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        values={this.state}

                      /> 
                      ):  <Thankyou/> }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>
          );
          case 5:
            return (
              <div>
              <Header />
              <section className="multi-bg">
                <div className="container" id="formcontainer">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-start text-center">
                        <h1 className="step-heading thanks-heading">
                        Thank you for completing our survey.
                        <br></br>
                        We always value feedback.
  
                        <hr />
                        </h1>
                        <form id="helpdeskform" name="helpdeskform" method="post">
                        <Thankyou/> 
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Footer />
            </div>
            );
          default:
                return
  }
  }
}
