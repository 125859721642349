import React from "react";

const Footer = (props) => {
  return (
<section className="upper_footer">
	<div className="container">
		<div className="row">
			<div className="col-md-12">
			<br/>

				<p>Copyright 2023 WiserAdvisor.com All Rights Reserved.
				</p>
				
			</div>
		</div>
	</div>
</section>
 );
};

export default Footer;