import React, { Component } from "react";
import api from '../service'
import axios from "axios";

class Slide2_4 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      spokenTo: [], 
      errors:{},
    };
  }
   


  componentDidMount() {
    var config = {
      method: "get",
      url: api.getting_user_details+this.props.values.requestId,
      headers: {},
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

          this.setState({
            spokenTo: response.data

        });
        })
        .catch((error) => {
          console.log(error);
        });
  }


   handlesubmit = (event) => {
    if (this.validateForm()) {
      if(this.props.values.hiring === "35"){
      var  hiringother = this.props.values.hiringothers
      }
      else{
       var hiringother = "NA"
      }
      const obj18 = {
          "requestid": this.props.values.requestId,
          "medium": this.props.values.medium,
          "answerId":  this.props.values.hiring,
          "questionId": "14",
          "responseInfo": hiringother,
          "createDt": this.props.values.date
      }

       const obj19 = {
          "requestid": this.props.values.requestId,
          "medium": this.props.values.medium,
          "answerId":  "36",
          "questionId": "15",
          "responseInfo": this.props.values.selectspokenTo.toString(),
          "createDt": this.props.values.date
      }


      if(this.props.values.assist_with===""){
      var assist_with = "NA"
      }
      else{
        var assist_with = this.props.values.assist_with
      }

      const obj20 = {
          "requestid": this.props.values.requestId,
          "medium": this.props.values.medium,
          "answerId":  "37",
          "questionId": "16",
          "responseInfo": assist_with,
          "createDt": this.props.values.date
      }
      // console.log(obj18,obj19,obj20)
       this.props.handleSubmit3(obj18,obj19,obj20);
    }
  }

   validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
     if (this.props.values.hiring==="") {
        formIsValid = false;
        errors["hiringerror"] = "Kindly select an option below to proceed";
      }

      if(this.props.values.hiring === "35" && this.props.values.hiringothers === ""){
        formIsValid = false;
        errors["hiring_other_error"] = "This is a required field";
      }
      // if(this.props.values.assist_with === ""){
      //   formIsValid = false;
      //   errors["assist_with_error"] = "Kindly select an option below to proceed";
      // }
      if(!this.props.values.selectspokenTo.length){
        formIsValid = false;
        errors["spokenToerror"] = "Kindly select an option below to proceed";
    }
    this.setState({
        errors: errors
    });
    return formIsValid;

  }

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {handleInput,values,handlecheckbox4} = this.props;
    const {spokenTo} = this.state;
    const {checkedItems} = this.state;
    return (
     <>
      <section className="feedback-service">
        <div className="container" style={{ textAlign: "left" }}>
          <div className="row">
            <div className="col-md-12">
            <h2>What do you mainly look for when hiring an advisor? 
              <div id="rsp_email" className="error">{this.state.errors.hiringerror}</div>
            </h2>
       
            <label className="radio-inline">
              <input type="radio" name="hiring"  checked={values.hiring==="30"} value="30" onChange={handleInput(this)} />Personality fit
            </label>
            <label className="radio-inline">
              <input type="radio" name="hiring" checked={values.hiring==="31"} value="31"  onChange={handleInput(this)} />Years of experience
            </label>
            <label className="radio-inline">
              <input type="radio" name="hiring"  checked={values.hiring==="32"} value="32" onChange={handleInput(this)} />Successful track record
            </label>
            <label className="radio-inline">
              <input type="radio" name="hiring" checked={values.hiring==="34"} value="34" onChange={handleInput(this)}/>Location
            </label>
            <label className="radio-inline">
              <input type="radio" name="hiring" value="33" checked={values.hiring==="33"} onChange={handleInput(this)} />Expertise in specific services
            </label>
            <label className="radio-inline">
              <input type="radio" name="hiring" value="35"  checked={values.hiring==="35"} onChange={handleInput(this)} />Other
            </label>
             <input type="text" placeholder="IF OTHER PLEASE SPECIFY" className="additional" value={values.hiringothers} name="hiringothers" onChange={handleInput(this)} disabled={values.hiring !=="35" ? 'disabled' : null} />
            <div id="rsp_email" className="error">{this.state.errors.hiring_other_error}</div>
      </div>



      <div className="col-md-12">
        <h2>Which of the advisor matches have you spoken to?
          <div id="rsp_email" className="error">{this.state.errors.spokenToerror}</div>
        </h2>
       {spokenTo.map((checkbox) =>
          <label className="radio-inline" key={checkbox.sellerId}>
          <input type="checkbox" disabled={values.checknon1 === "None of the above"} checked={values.selectspokenTo.includes(checkbox.sellerNm + "(Sid-"+ checkbox.sellerId+ ")")} name={checkbox.sellerNm} value={checkbox.sellerId} onChange={handlecheckbox4} />
          {checkbox.sellerNm}
          </label>
        )} 

        <label className="radio-inline">
            <input
              type="checkbox"
              name="selectspokenTo"
              checked={values.selectspokenTo.includes("None of the above")}
              value="None of the above"
              onChange={handlecheckbox4} 
            />
            None of the above
          </label>
    
      </div>
  


      <div className="col-md-12">
        <h2>Is there anything we can do to assist you with your decision making process?</h2>
          <input type="text" className="additional" name="assist_with" value={values.assist_with} onChange={handleInput(this)}></input>
          <div id="rsp_email" className="error">{this.state.errors.assist_with_error}</div>
      </div>


      <div className="col-md-3"></div>
      <div className="col-md-6">
        <input type="button" name="previous" className="next btn-update action-button nextOne" style={{cursor: "pointer"}} value="PREVIOUS" onClick={this.back}/>
        <input type="button" name="next" className="next btn-update action-button nextOne" style={{cursor: "pointer", marginLeft: "30px"}} value="NEXT" onClick={this.handlesubmit}/>
      </div>
    </div>

  </div>

  </section>
 </>
  );
  }
  }
  
  export default Slide2_4;