import React, {Component} from 'react';

// npm install @babel/core @babel/preset-env

class Slide1 extends Component {
	
	
	continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    back = (e) => {
      e.preventDefault();
      this.props.prevStep();
    };
 
  render(){
   const {handleClick,values} = this.props;
   return (
   <>
     					
     <fieldset className="field7 current" style={{display: 'block'}}>
							
								<h2>What's the status of your<br></br> online request for a financial advisor?</h2>
								<p>[Select an option below]</p>
								<div className="row top-space justify-content-md-center justify-content-center justify-content-sm-center">
									<div className="col col-md-3 text-center bottom-space">
										<figure  id="field7_id1" className={values.status==="1" ? 'icon-block next icon-active': 'icon-block next'} name="radio1"  onClick={handleClick('status','1')}> 
											<svg xmlns="http://www.w3.org/2000/svg" width="50.052" height="49.888" viewBox="0 0 50.052 49.888">
												<g id="Group_119" data-name="Group 119" transform="translate(-498.189 -385.502)">
													<ellipse id="Ellipse_55" data-name="Ellipse 55" cx="8.624" cy="10.388" rx="8.624" ry="10.388" transform="translate(503.452 385.502)" fill=""/>
													<path id="Path_92" data-name="Path 92" d="M541.312,417.925v-4.47h-10.5v4.47h-6.929V435.39h24.355V417.925Zm-1.879,0h-6.739v-2.44h6.739Z" fill=""/>
													<path id="Path_93" data-name="Path 93" d="M520.582,411.431a8.654,8.654,0,0,1-6.347,9.384l-.532-6.259,3.087-3.125h-9.427l3.087,3.125-.531,6.259a8.65,8.65,0,0,1-6.409-8.384,8.915,8.915,0,0,1,.061-1,14.085,14.085,0,0,0-5.382,11.1v7.019a19.4,19.4,0,0,0,23.574,3.247V415.8h2.482A14.128,14.128,0,0,0,520.582,411.431Z" fill=""/>
												</g>
												</svg>

										</figure>
										<h4>FOUND MY ADVISOR</h4>
									</div>
									<div className="col col-md-3 text-center bottom-space">
										<figure  className={values.status==="2" ? 'icon-block next icon-active': 'icon-block next'} id="field7_id2" name="radio1" defaultValue="78408" onClick={handleClick('status','2')}>
										<svg xmlns="http://www.w3.org/2000/svg" width="60.443" height="59.446" viewBox="0 0 60.443 59.446">
											<g id="Group_115" data-name="Group 115" transform="translate(-651.978 -382.467)">
												<g id="Group_114" data-name="Group 114" transform="translate(1 -2)">
												<path id="Path_85" data-name="Path 85" d="M694.091,404.148c0,5.921-3.932,10.721-8.783,10.721s-8.784-4.8-8.784-10.721,3.933-10.721,8.784-10.721S694.091,398.227,694.091,404.148Z" fill=""/>
												<path id="Path_86" data-name="Path 86" d="M699.011,429.929v-.294a13.653,13.653,0,0,0-5.31-10.808,8.4,8.4,0,0,1-6.264,9.139l-.524-6.1,3.046-3.043h-9.3l3.046,3.043-.524,6.1a8.386,8.386,0,0,1-6.264-9.139,13.651,13.651,0,0,0-5.311,10.808v2.725a23.654,23.654,0,0,0,27.407-2.431Z" fill=""/>
												</g>
												<path id="Path_87" data-name="Path 87" d="M662.271,431.886l-3.308-3.3-7.985,4.557c2.919,4.143,4.762,5.986,10.782,10.775l5.588-6.954-2.833-2.831,2.934-2.879-2.244-2.242Z" transform="translate(1 -2)" fill=""/>
												<path id="Path_88" data-name="Path 88" d="M684.637,384.467A26.715,26.715,0,0,0,664.846,429.2l-8.571,8.409,2.678,2.676,8.612-8.451a26.768,26.768,0,1,0,17.072-47.371Zm0,49.746a22.981,22.981,0,1,1,23-22.98A23.016,23.016,0,0,1,684.637,434.213Z" transform="translate(1 -2)" fill=""/>
											</g>
											</svg>

										</figure>
										<h4>INTERVIEWING ADVISORS</h4>
									</div>
									<div className="col col-md-3 text-center bottom-space">
										<figure className={values.status==="3" ? 'icon-block next icon-active': 'icon-block next'} id="field7_id3" name="radio1" defaultValue="78409" onClick={handleClick('status','3')} >
										<svg xmlns="http://www.w3.org/2000/svg" width="49.528" height="49.528" viewBox="0 0 49.528 49.528">
											<g id="Group_118" data-name="Group 118" transform="translate(-842.907 -385.682)">
												<path id="Path_89" data-name="Path 89" d="M878.943,408.225a13.492,13.492,0,1,0,13.492,13.493A13.493,13.493,0,0,0,878.943,408.225Zm5.414,13.529-4.921,1.541a1.637,1.637,0,0,1-.494.076,1.653,1.653,0,0,1-1.653-1.653V413.35a1.654,1.654,0,1,1,3.307,0v6.118l2.773-.868a1.653,1.653,0,1,1,.988,3.154Z" fill=""/>
												<path id="Path_90" data-name="Path 90" d="M864.384,388.415a7.258,7.258,0,0,0-2.845-2.329,7.129,7.129,0,0,0-2.912-.395,27.308,27.308,0,0,0-3.944.518,10.621,10.621,0,0,0-2.622.726,8.68,8.68,0,0,0-3.253,3.045,22.716,22.716,0,0,0-2.965,5.907,9.4,9.4,0,0,0-.442,5.4,8.457,8.457,0,0,0,1.146,2.377,3.245,3.245,0,0,0,1.177,1.19,1.344,1.344,0,0,0,1.578-.2,1.179,1.179,0,0,0,.169-.289,8.423,8.423,0,0,0,7.42,5.06,7.953,7.953,0,0,0,6.047-2.941c.038.017.071.046.11.059a3.114,3.114,0,0,0,1.526.008,5.986,5.986,0,0,0,2.171-.653,3.782,3.782,0,0,0,1.565-2.132,8.531,8.531,0,0,0,.3-2.65A20.587,20.587,0,0,0,864.384,388.415Zm-7.493,19.34c-3.326,0-6.094-2.913-6.693-6.748a26.323,26.323,0,0,0,.284-4.289,8.766,8.766,0,0,1,1.551-3.068,5.382,5.382,0,0,1,1-.048,25.247,25.247,0,0,1,2.828.464c.6.1,1.4.058,1.585-.505a2.716,2.716,0,0,0,.011-.8,1.558,1.558,0,0,1,1.107-1.32c2.951.913,5.15,4.145,5.15,8.014C863.712,404.034,860.652,407.755,856.891,407.755Z" fill=""/>
												<path id="Path_91" data-name="Path 91" d="M863.343,421.717a15.522,15.522,0,0,1,.574-4.136,8.873,8.873,0,0,1-4.639,3.066c-.073.021-.14.056-.215.075V420.7a8.8,8.8,0,0,1-4.342,0l0,.021c-.074-.019-.142-.054-.215-.075a8.64,8.64,0,0,1-6.45-8.277c0-.261.016-.518.04-.773a13.917,13.917,0,0,0-5.186,10.828V429.4a19.733,19.733,0,0,0,24.494,2.773A15.52,15.52,0,0,1,863.343,421.717Z" fill=""/>
											</g>
											</svg>

										</figure>
										<h4>NEED MORE TIME TO DECIDE</h4>
									</div>
									<div className="col col-md-3 text-center bottom-space">
										<figure className={values.status==="4" ? 'icon-block next icon-active': 'icon-block next'} id="field7_id4" name="radio1" defaultValue="78410" onClick={handleClick('status','4')}>
										<svg xmlns="http://www.w3.org/2000/svg" width="50.186" height="50.336" viewBox="0 0 50.186 50.336">
											<g id="Group_121" data-name="Group 121" transform="translate(-1008.989 -385.617)">
												<path id="Path_94" data-name="Path 94" d="M1036.278,385.617V400.53h3.168v6.306l4.376-6.306h15.353V385.617Zm5.011,8.222a1.582,1.582,0,1,1,1.544-1.582A1.564,1.564,0,0,1,1041.289,393.839Zm6.438,0a1.582,1.582,0,1,1,1.544-1.582A1.563,1.563,0,0,1,1047.727,393.839Zm6.438,0a1.582,1.582,0,1,1,1.544-1.582A1.564,1.564,0,0,1,1054.165,393.839Z" fill=""/>
												<g id="Group_120" data-name="Group 120">
												<ellipse id="Ellipse_56" data-name="Ellipse 56" cx="9.658" cy="10.209" rx="9.658" ry="10.209" transform="translate(1014.883 387.126)" fill=""/>
												<path id="Path_95" data-name="Path 95" d="M1024.541,435.953a22.219,22.219,0,0,0,15.552-6.223v-7.477a14.748,14.748,0,0,0-6.027-11.821,8.913,8.913,0,0,1,.069,1.064,9.306,9.306,0,0,1-7.177,8.932l-.595-6.668,3.456-3.328h-10.556l3.457,3.328-.595,6.668a9.306,9.306,0,0,1-7.178-8.932,8.913,8.913,0,0,1,.069-1.064,14.751,14.751,0,0,0-6.027,11.821v7.477A22.219,22.219,0,0,0,1024.541,435.953Z" fill=""/>
												</g>
											</g>
											</svg>

											</figure>
										<h4>OTHER</h4>
									</div>
								</div>
							</fieldset> 
   </> 
   )
  }
  }

export default Slide1;
